import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import './Contact.css';

const Contact = () => {
    return(
        <Row middle="xs" className="contact" center="xs">
            <Col xs={6}>
                <form action="https://formspree.io/xjvbadab" method="POST">
					<Row>
						<input type="text" name="name" placeholder="Your Name..." required />
					</Row>
					<Row>
						<input type="email" name="_replyto" placeholder="Your Email..." required />
					</Row>
					<Row>
						<textarea name="message" placeholder="Your Messsage..." required />
					</Row>
					<Row>
						<input type="submit" value="Send" />
					</Row>
				</form>
            </Col>
        </Row>
    )
}

export default Contact;