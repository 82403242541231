import React from 'react';
import './App.css';
import Home from './pages/Home';
import { Route, Switch, NavLink } from 'react-router-dom';
import { Row, Col, Grid } from 'react-flexbox-grid';
import AboutMe from './pages/AboutMe';
import Contact from './pages/Contact';
import Projects from './pages/Projects';


import PageNotFound from './pages/PageNotFound';
import pdf from './assets/martin_janos_cserep_cv.pdf';
import SocialBar from './components/SocialBar';

function App() {
  return (
    <Grid>
      <Row middle="xs" center="xs" around="xs" className="nav-bar">
        <Col sm={12} md={2} lg={2} xl={2}>
          <NavLink exact to="/about" className="nav-link" activeClassName="active">About Me</NavLink>
        </Col>
        <Col sm={12} md={2} lg={2} xl={2}>
          <NavLink exact to="/projects" className="nav-link" activeClassName="active">Projects</NavLink>
        </Col>
        <Col sm={12} md={2} lg={2} xl={2}>
          <NavLink exact to="/" className="nav-link my-name" activeClassName="active">Martin Cserep</NavLink>
        </Col>
        <Col sm={12} md={2} lg={2} xl={2}>
          <a href={pdf} target="_blank" rel="noopener noreferrer" className="nav-link">Resume</a>
        </Col>
        <Col sm={12} md={2} lg={2} xl={2}>
          <NavLink exact to="/contact" className="nav-link" activeClassName="active">Contact</NavLink>
        </Col>
      </Row>
      <div className="app-content">

        <Route render={({location}) => (
          <Switch location={location}>
                  <Route exact path="/" component={Home} />
                  <Route path="/about" component={AboutMe} />
                  <Route exact path="/projects" component={Projects} />
                  <Route path="/contact" component={Contact} />
                  <Route path="*" component={PageNotFound}/>
                </Switch>
        )} />
      </div>
      <SocialBar />
    </Grid>
  );
}

export default App;
