import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import thinking from './../../assets/images/thinking.png';
import './PageNotFound.css';
import { NavLink } from 'react-router-dom';

const PageNotFound = () => {
    return(
        <Row middle="xs" className="pnf" center="xs">
            <Col xs={12} md={6}>
                <Row center="xs" className='pnf-code'>404</Row>
				<Row center="xs" className='pnf-text'>I don't find this page</Row>
				<Row center="xs">
					<NavLink exact to="/" className="pnf-back" activeClassName="active">Go to home</NavLink>
				</Row>
            </Col>
			<Col xs={12} md={6}>
                <img className="thinking" src={thinking} alt='thinking' />
            </Col>
        </Row>
    )
}

export default PageNotFound;