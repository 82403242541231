import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import './Projects.css'
import ProjectItem from '../../components/common/ProjectItem';
import firebase from './../../firebase';

class Projects extends Component {
    constructor(props) {
      super(props);
      this.ref = firebase.firestore().collection('projects');
      this.unsubscribe = null;
      this.state = {
        projects: []
      };
    }

  onCollectionUpdate = (querySnapshot) => {
    const projects = [];
    querySnapshot.forEach((doc) => {
      const { name, desc, tech, url } = doc.data();
      projects.push({
        key: doc.id,
        name,
        desc,
        tech,
        url,
      });
    });
    this.setState({
      projects
   });
   console.log(projects)
  }

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }

    render() {

    
    return(
        <>
        <Row>
            <Col xs={12} className="page-title">Projects I worked on.</Col>
        </Row>
        <Row middle="xs" className="page-content">
        {this.state.projects.map(project =>
                  <ProjectItem index={project.key} name={project.name} desc={project.desc} tech={project.tech} url={project.url} />
            )}
        </Row>

        </>
    )
}
}

export default Projects;