import * as firebase from 'firebase';

const settings = {timestampsInSnapshots: true};

const firebaseConfig = {
    apiKey: "AIzaSyB2kihKk5suwpJucEoCH_tIT28v8UVLrvQ",
    authDomain: "martincserep-b1a71.firebaseapp.com",
    databaseURL: "https://martincserep-b1a71.firebaseio.com",
    projectId: "martincserep-b1a71",
    storageBucket: "martincserep-b1a71.appspot.com",
    messagingSenderId: "889956307938",
    appId: "1:889956307938:web:f5d2ffe87b9d4dd8205fbb",
    measurementId: "G-QM64KMQJ03"
  };
firebase.initializeApp(firebaseConfig);

firebase.firestore().settings(settings);

export default firebase;