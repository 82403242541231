import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import hi from './../../assets/images/hi.png';
import './AboutMe.css'

const AboutMe = () => {
    return(
        <>
        <Row>
            <Col xs={12} className="page-title">About Me</Col>
        </Row>
        <Row center="xs" middle="xs" className="page-content">
            <Col className="about-me-desc" md={6}>
                <p>
                    Hi there <span role="img" aria-label="wawing-hand">👋</span>
                </p>
                <p>
                    I'm Martin Cserep from Hungary <span role="img" aria-label="flag-hungarian">🇭🇺</span>
                </p>
                <p>
                    I love to learn new skills or techniques that inspire me to create cool things.
                </p>
                <p>
                    I love sports, I like cycling, swimming, kayaking. I also love tennis and motorsports, but I've never tried these two.
                </p>
                <p>
                    I recently started a <a href="https://blog.martincserep.hu/" rel="noopener noreferrer" target="_blank">blog</a> where I don’t have a lot of content, but I try to produce more content. The blog is currently only available in Hungarian.
                </p>
                <p>
                    I would like to start a twitch channel where I making games and apps in live. <span role="img" aria-label="macbook">💻</span>
                </p>
                <p>
                    I'm a huge music lover, I love listening to music <span role="img" aria-label="headphone">🎧</span> and I usually play on piano <span role="img" aria-label="piano">🎹</span></p>
                <p>
                    Also I have a dog, Joey, a tiny little bichon bolognese <span role="img" aria-label="dog-face">🐶</span>
                </p>
            </Col>
            <Col md={6}>
                <img className='hi-image' src={hi} alt='hi'/>
            </Col>
        </Row>

        </>
    )
}

export default AboutMe;