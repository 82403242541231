import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import SimpleLineIcon from 'react-simple-line-icons';

import './ProjectItem.css'
// import HeaderH3 from '../HeaderH3'

/**
* @author martincserep
* @function ProjectItem
* */

const ProjectItem = props => {

	const { index, name, desc, tech, url } = props

	return (
		<Row key={index}  className='project-item' middle="xs" start="xs">
            <Col className='link-container' xs={1} >
                <a href={url} rel="noopener noreferrer" target="_blank" className='link'>
                    <SimpleLineIcon name="link" size='large' />
                </a>
            </Col>
            <Col Col xs={10}>
                <Row className='project-item-title'>
                    <Col xs={12} >
                        {name}
                    </Col>
                </Row>
                <Row>
                    <Row className='project-item-desc'>
                        <Col xs={12} >
                            {desc}
                        </Col>
                    </Row>
                    <Row className='project-item-tech'>
                            {tech.map(element => {
                                return(
                                    <Col className='project-item-tech-item'>
                                        {element}
                                    </Col>
                                )
                            })}
                    </Row>
                </Row>
            </Col>
		</Row>
	)
}

ProjectItem.propTypes = {
	desc: PropTypes.string.isRequired,
	index: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	tech: PropTypes.arrayOf(PropTypes.string).isRequired,
	url: PropTypes.string.isRequired,
}

export default ProjectItem