import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import './Home.css'

const Home = () => {
    return(
        <>
            <Row middle="xs" className='introduction'>
                <Col>
                    I'm Martin Cserep, an enthusiastic junior frontend developer from Hungary <span role="img" aria-label="flag-hungarian">🇭🇺</span>
                </Col>
            </Row>
            <hr />
            <Row middle="xs" className='how-i-work'>
                <Col>
                    In my daily ride I use <span className='work-item'>Javascript</span>, <span className='work-item'>CSS Modules</span>, <span className='work-item'>React</span>, <span className='work-item'>Redux</span> and <span className='work-item'>React Hooks</span>. I always try to write <span className='work-item'>clean</span> and <span className='work-item'>quality</span> code.
                </Col>
            </Row>
        </> 
    )
}

export default Home;