import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import SimpleLineIcon from 'react-simple-line-icons';

const SocialBar = () => {
    return(
        <Row className='social' center="xs" around="xs">
        <Col xs={2}>
          <a className="nav-link" href="https://www.linkedin.com/in/martincserep/" rel="noopener noreferrer" target="_blank">
            <SimpleLineIcon name="social-linkedin" size='large' />
          </a>
        </Col>
        <Col xs={2}>
          <a className="nav-link" href="https://twitter.com/martincserep/" rel="noopener noreferrer" target="_blank">
            <SimpleLineIcon name="social-twitter" size='large' />
          </a>
        </Col>
        <Col xs={2}>
          <a className="nav-link" href="https://blog.martincserep.hu/" rel="noopener noreferrer" target="_blank">
            <SimpleLineIcon name="notebook" size='large' />
          </a>
        </Col>
        <Col xs={2}>
          <a className="nav-link" href="https://instagram.com/martin.cserep/" rel="noopener noreferrer" target="_blank">
            <SimpleLineIcon name="social-instagram" size='large' />
          </a>
        </Col>
        <Col xs={2}>
          <a className="nav-link" href="https://github.com/martincserep/" rel="noopener noreferrer" target="_blank">
            <SimpleLineIcon name="social-github" size='large' />
          </a>
        </Col>
      </Row>
    )
}

export default SocialBar;